import { DrwParser } from './DrwParser';
import { DrwRenderer } from './DrwRenderer';

let drwFile: ArrayBuffer;
let width: number = 512;

const uploadButton = document.getElementById('drw');
const widthField = document.getElementById('width');
const applyButton = document.getElementById('apply');

uploadButton.addEventListener('change',
  ({ target }) => {
    (<HTMLInputElement>target).files[0].arrayBuffer().then(
      buf => { drwFile = buf; }
    );
  }
);

widthField.addEventListener('change',
  ({ target }) => {
    width = Number((<HTMLInputElement>target).value);
  }
);

applyButton.addEventListener('click', () => {
  (new DrwRenderer(new DrwParser(drwFile))).prepare()
    .then(
      renderer => {
        const existingCanvases = document.getElementsByTagName('canvas');
        let ctx: CanvasRenderingContext2D;

        if (existingCanvases.length > 0) ctx = existingCanvases[0].getContext('2d');
        else ctx = document.createElement('canvas').getContext('2d');
        const canvas = ctx.canvas;

        renderer.setCanvasWidth(width);
        canvas.height = renderer.height;
        canvas.width = renderer.width;

        const m1 = performance.now();

        renderer.onUpdate(() => {
          // Draw the result to the main canvas
          renderer.blitTo(ctx);
        });

        renderer.seekEnd();

        const m2 = performance.now();

        console.log('render time:', m2 - m1)

        document.body.appendChild(canvas);
        (<any>window).renderer = renderer; // make global for easy debugging
      }
    );
});
